import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
import { ALL_PLACEMENTS, EXTRA_ANIMATIONS } from '../utils';
import Dropdown from '../components/examples/Dropdown';
import Singleton from '../components/examples/Singleton';
import Nesting from '../components/examples/Nesting';
import Multiple from '../components/examples/Multiple';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Emoji = makeShortcode("Emoji");
const Demo = makeShortcode("Demo");
const Tippy = makeShortcode("Tippy");
const Button = makeShortcode("Button");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Tippy.js is a highly customizable tooltip and popover library powered by
`}<a parentName="p" {...{
        "href": "https://popper.js.org"
      }}>{`Popper.js`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><Emoji emoji="🧠" mdxType="Emoji" /> <strong>Smart:</strong> optimized positioning engine for flipping and overflow
prevention</li>
      <li parentName="ul"><Emoji emoji="⚡" mdxType="Emoji" /> <strong>Fast:</strong> high performance for low-end devices</li>
      <li parentName="ul"><Emoji emoji="🖱️" mdxType="Emoji" /> <strong>Universal:</strong> works with mouse, keyboard, and touch inputs</li>
      <li parentName="ul"><Emoji emoji="♿" mdxType="Emoji" /> <strong>Accessible:</strong> WAI-ARIA compatible</li>
      <li parentName="ul"><Emoji emoji="🖌" mdxType="Emoji" /> <strong>Themeable:</strong> style via custom CSS, includes extra themes and animations</li>
      <li parentName="ul"><Emoji emoji="🔌" mdxType="Emoji" /> <strong>Pluggable:</strong> enhance functionality with plugins</li>
      <li parentName="ul"><Emoji emoji="🌳" mdxType="Emoji" /> <strong>Tree-shakable:</strong> minimize bundle size by importing what you need</li>
      <li parentName="ul"><Emoji emoji="🧩" mdxType="Emoji" /> <strong>Typed:</strong> TypeScript support out of the box</li>
      <li parentName="ul"><Emoji emoji="🌐" mdxType="Emoji" /> <strong>Supports IE11+:</strong> compatible with 99% of desktop and 97% of mobile users</li>
    </ul>
    <p>{`Ready to start? Visit `}<a parentName="p" {...{
        "href": "/getting-started/"
      }}>{`Getting Started`}</a>{`, or view a demo of
Tippy's features below.`}</p>
    <hr></hr>
    <h3>{`Default`}</h3>
    <p>{`The default tippy tooltip looks like this:`}</p>
    <Demo mdxType="Demo">
  <Tippy mdxType="Tippy">
    <Button mdxType="Button">My Button</Button>
  </Tippy>
    </Demo>
    <p>{`It has a background color of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#333`}</code>{` and an arrow pointing toward the element,
and is triggered by either `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mouseenter`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`focus`}</code>{` events so it appears when
hovered, focused via keyboard navigation, or tapped when using a touch device.`}</p>
    <p>{`With a button element on the document like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`myButton`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`My Button`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`You can initialize it like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`#myButton`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  content`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`I'm a Tippy tooltip!`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <hr></hr>
    <h3>{`Placement`}</h3>
    <p>{`Tooltips can be placed in four base ways in relation to the reference element.
Additionally, the tooltip can be shifted along the axis using the suffix
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-start`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-end`}</code>{`.`}</p>
    <Demo mdxType="Demo">
  <Row mdxType="Row">
    {ALL_PLACEMENTS.map((placement, i) => <Col key={placement} base={12} xs={6} md={4} lg={6} xl={4} style={{
          margin: '8px 0'
        }} mdxType="Col">
        <Tippy key={placement} content="Tooltip" placement={placement} flip={false} arrow={false} mdxType="Tippy">
          <Button style={{
              display: 'inline-block',
              width: '100%',
              height: '100%'
            }} mdxType="Button">
            {placement}
          </Button>
        </Tippy>
      </Col>)}
  </Row>
    </Demo>
    <p>{`If a tippy cannot fit within its desired placement, it will flip to the opposite
placement if there is not enough space. In the above examples, flipping has been
disabled to demonstrate each placement properly.`}</p>
    <hr></hr>
    <h3>{`Arrows`}</h3>
    <p>{`The arrow that points toward the element can have its proportion or shape
modified, or be disabled completely.`}</p>
    <Demo mdxType="Demo">
  <Tippy arrow animation="fade" mdxType="Tippy">
    <Button mdxType="Button">Default</Button>
  </Tippy>
  <Tippy arrow="round" animation="fade" animation="fade" mdxType="Tippy">
    <Button mdxType="Button">Round</Button>
  </Tippy>
  <Tippy animation="fade" theme="large-arrow" distance={16} mdxType="Tippy">
    <Button mdxType="Button">Large</Button>
  </Tippy>
  <Tippy animation="fade" theme="small-arrow" distance={8} mdxType="Tippy">
    <Button mdxType="Button">Small</Button>
  </Tippy>
  <Tippy animation="fade" theme="wide-arrow" mdxType="Tippy">
    <Button mdxType="Button">Wide</Button>
  </Tippy>
  <Tippy animation="fade" theme="narrow-arrow" mdxType="Tippy">
    <Button mdxType="Button">Narrow</Button>
  </Tippy>
    </Demo>
    <hr></hr>
    <h3>{`Animations`}</h3>
    <p>{`Tooltips can have different types of transition animations. By default, it's a
simple `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fade`}</code>{` (opacity transition).`}</p>
    <h4>{`Extra included animations`}</h4>
    <p>{`These animations are included in the package and can be imported separately.`}</p>
    <Demo mdxType="Demo">
  <Row mdxType="Row">
    {EXTRA_ANIMATIONS.map(animation => <Col key={animation} base={12} md={4} lg={6} xl={4} style={{
          margin: '8px 0'
        }} mdxType="Col">
        <Tippy animation={animation} animateFill={false} mdxType="Tippy">
          <Button style={{
              width: '100%',
              height: '100%'
            }} mdxType="Button">
            {animation}
          </Button>
        </Tippy>
      </Col>)}
  </Row>
    </Demo>
    <h4>{`Material filling effect`}</h4>
    <Demo mdxType="Demo">
  <Tippy animateFill={true} animation="shift-away" mdxType="Tippy">
    <Button mdxType="Button">Text</Button>
  </Tippy>
    </Demo>
    <h4>{`Inertia / slingshot elastic effect`}</h4>
    <p>{`Add CSS spring physics to the animation using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition-timing-function`}</code>{`.`}</p>
    <Demo mdxType="Demo">
  {EXTRA_ANIMATIONS.filter(animation => animation.includes('scale')).map(animation => <Tippy key={animation} animation={animation} animateFill={false} inertia={true} duration={[450, 175]} mdxType="Tippy">
        <Button mdxType="Button">{animation}</Button>
      </Tippy>)}
    </Demo>
    <h4>{`CSS keyframe animations`}</h4>
    <p>{`Getting more advanced, you can use actual CSS animations (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@keyframes`}</code>{` rules),
for example using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animate.css`}</code>{` package:`}</p>
    <Demo mdxType="Demo">
  <Tippy animation="fade" animateFill={false} duration={[500, 200]} onMount={instance => {
        requestAnimationFrame(() => {
          instance.popperChildren.tooltip.classList.add('rubberBand', 'animated');
        });
      }} onHidden={instance => {
        instance.popperChildren.tooltip.classList.remove('rubberBand', 'animated');
      }} mdxType="Tippy">
    <Button mdxType="Button">rubberBand</Button>
  </Tippy>
  <Tippy animation="fade" animateFill={false} duration={[500, 200]} onMount={instance => {
        requestAnimationFrame(() => {
          instance.popperChildren.tooltip.classList.add('tada', 'animated');
        });
      }} onHidden={instance => {
        instance.popperChildren.tooltip.classList.remove('tada', 'animated');
      }} mdxType="Tippy">
    <Button mdxType="Button">tada</Button>
  </Tippy>
    </Demo>
    <h4>{`Duration`}</h4>
    <Demo mdxType="Demo">
  <Tippy duration={0} mdxType="Tippy">
    <Button mdxType="Button">0</Button>
  </Tippy>
  <Tippy duration={1000} mdxType="Tippy">
    <Button mdxType="Button">1000</Button>
  </Tippy>
  <Tippy duration={[0, 500]} mdxType="Tippy">
    <Button mdxType="Button">[0, 500]</Button>
  </Tippy>
  <Tippy duration={[500, 0]} mdxType="Tippy">
    <Button mdxType="Button">[500, 0]</Button>
  </Tippy>
    </Demo>
    <hr></hr>
    <h3>{`Themes`}</h3>
    <p>{`Tooltips can have custom styling.`}</p>
    <h4>{`Included themes`}</h4>
    <p>{`These themes are included in the package and can be imported separately.`}</p>
    <Demo mdxType="Demo">
  {['light', 'light-border', 'material', 'translucent'].map(theme => <Tippy key={theme} theme={theme} animateFill={false} mdxType="Tippy">
      <Button mdxType="Button">{theme}</Button>
    </Tippy>)}
    </Demo>
    <h4>{`Custom themes`}</h4>
    <p>{`You can apply any CSS to a tippy via a theme.`}</p>
    <Demo mdxType="Demo">
  {['gradient', 'retro', 'forest'].map(theme => <Tippy key={theme} theme={theme} animateFill={false} arrow={false} mdxType="Tippy">
      <Button mdxType="Button">{theme}</Button>
    </Tippy>)}
    </Demo>
    <hr></hr>
    <h3>{`Triggers`}</h3>
    <p>{`Tooltips can also be triggered by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`click`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`focus`}</code>{` events.`}</p>
    <Demo mdxType="Demo">
  <Tippy trigger="click" mdxType="Tippy">
    <Button mdxType="Button">Click</Button>
  </Tippy>
  <Tippy trigger="focus" hideOnClick={false} mdxType="Tippy">
    <Button mdxType="Button">Focus</Button>
  </Tippy>
    </Demo>
    <hr></hr>
    <h3>{`Interactivity`}</h3>
    <p>{`Tooltips can be interactive, allowing you to hover over and click inside them.`}</p>
    <Demo mdxType="Demo">
  <Tippy interactive content="You can select the text inside here." mdxType="Tippy">
    <Button mdxType="Button">Interactive</Button>
  </Tippy>
    </Demo>
    <hr></hr>
    <h3>{`HTML Content`}</h3>
    <p>{`Tooltips can contain HTML.`}</p>
    <Demo mdxType="Demo">
  <Tippy content={<strong>
        Bolded <span style={{
          color: 'aqua'
        }}>content</span>
      </strong>} mdxType="Tippy">
    <Button mdxType="Button">HTML Content</Button>
  </Tippy>
  <Dropdown mdxType="Dropdown" />
    </Demo>
    <hr></hr>
    <h3>{`Delay`}</h3>
    <p>{`Tooltips can delay hiding or showing after a trigger.`}</p>
    <Demo mdxType="Demo">
  <Tippy delay={500} mdxType="Tippy">
    <Button mdxType="Button">500</Button>
  </Tippy>
  <Tippy delay={[800, 0]} mdxType="Tippy">
    <Button mdxType="Button">[800, 0]</Button>
  </Tippy>
  <Tippy delay={[0, 800]} mdxType="Tippy">
    <Button mdxType="Button">[0, 800]</Button>
  </Tippy>
    </Demo>
    <hr></hr>
    <h3>{`Follow Cursor`}</h3>
    <p>{`Tooltips can follow the mouse cursor and abide by a certain axis. Additionally,
the tooltip can follow the cursor until it shows, at which point it will stop
following (initial).`}</p>
    <Demo mdxType="Demo">
  <Tippy followCursor animateFill={false} duration={200} animation="fade" mdxType="Tippy">
    <Button mdxType="Button">Default</Button>
  </Tippy>
  <Tippy followCursor="horizontal" animateFill={false} duration={200} animation="fade" mdxType="Tippy">
    <Button mdxType="Button">Horizontal</Button>
  </Tippy>
  <Tippy followCursor="vertical" animateFill={false} duration={200} animation="fade" mdxType="Tippy">
    <Button mdxType="Button">Vertical</Button>
  </Tippy>
  <Tippy followCursor="initial" animateFill={false} delay={200} duration={200} animation="fade" mdxType="Tippy">
    <Button mdxType="Button">Initial</Button>
  </Tippy>
    </Demo>
    <hr></hr>
    <h3>{`SVGs`}</h3>
    <p>{`Tooltips can be placed on SVG nodes, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`followCursor: 'initial'`}</code>{` becomes
very useful, since it can be placed directly on the line.`}</p>
    <svg height="150" width="150">
  <Tippy followCursor="initial" animation="fade" delay={100} mdxType="Tippy">
    <line x1="0" y1="0" x2="150" y2="150" style={{
          stroke: 'tomato',
          strokeWidth: 5
        }} />
  </Tippy>
    </svg>
    <hr></hr>
    <h3>{`Singleton`}</h3>
    <p>{`Use a single tooltip for many different reference elements. This allows you to
"group" tooltips with a shared timer to improve UX when elements near each other
have tooltips with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`delay`}</code>{` prop.`}</p>
    <p>{`Non-singleton tippy with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`delay: 500`}</code>{`:`}</p>
    <Demo mdxType="Demo">
  <Singleton mdxType="Singleton" />
    </Demo>
    <p>{`Singleton tippy to group each tippy's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`delay: 500`}</code>{`:`}</p>
    <Demo mdxType="Demo">
  <Singleton group mdxType="Singleton" />
    </Demo>
    <p>{`Singleton tippy with a transition:`}</p>
    <Demo mdxType="Demo">
  <Singleton group transition mdxType="Singleton" />
    </Demo>
    <hr></hr>
    <h3>{`Nesting`}</h3>
    <p>{`A tippy can be nested within another one.`}</p>
    <Demo mdxType="Demo">
  <Nesting mdxType="Nesting" />
    </Demo>
    <p>{`This allows you to create a hover menu system.`}</p>
    <hr></hr>
    <h3>{`Multiple`}</h3>
    <p>{`Attach many tippies to a single element.`}</p>
    <Demo mdxType="Demo">
  <Multiple mdxType="Multiple" />
    </Demo>
    <hr></hr>
    <h3>{`Miscellaneous`}</h3>
    <Demo mdxType="Demo">
  <Tippy offset={50} mdxType="Tippy">
    <Button mdxType="Button">Offset</Button>
  </Tippy>
  <Tippy distance={20} mdxType="Tippy">
    <Button mdxType="Button">Distance</Button>
  </Tippy>
  <Tippy maxWidth={100} mdxType="Tippy">
    <Button mdxType="Button">Max width</Button>
  </Tippy>
    </Demo>
    <br />
    <p>{`The above is not a complete list of features. There are plenty more!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      